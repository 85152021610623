import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useBookingStatus = () => {
  const toast = useToast();

  const getBookingStatusList = async () => {
    try {
      return await store.dispatch('bookingStatus/getBookingStatus');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Booking status not found',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    getBookingStatusList,
  };
};

export default useBookingStatus;
