import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useUtils from '@/composables/useUtils';

export default function useBookingList() {
  const toast = useToast();
  const { formatCurrency, convertUtcToLocalDate, formatDateDdMmYyyyHi } = useUtils();

  const perPageOptions = [10, 25, 50, 100];
  const tableColumns = [
    { key: 'voucherNum', sortable: true },
    { key: 'bookingType', label: 'type' },
    { key: 'bookingStatusId', label: 'status' },
    { key: 'company', sortable: true },
    {
      key: 'totalBookingFee',
      label: 'total',
      sortable: true,
      formatter: (val) => `${formatCurrency(val)}`,
    },
    {
      key: 'bookedForDateUtc',
      label: 'booking date',
      sortable: true,
      sortable: true,
      formatter: (val) => `${formatDateDdMmYyyyHi(convertUtcToLocalDate(val))}`,
    },
    { key: 'actions' },
  ];

  const bookingTypeFilter = ref(null);
  const bookingStatusFilter = ref(null);
  const pageNumber = ref(1);
  const orderDesc = ref(true);
  const pageSize = ref(10);
  const refBookingListTable = ref(null);
  const searchQuery = ref(null);
  const orderBy = ref('id');
  const totalBookings = ref(0);
  const searchByDate = ref('');

  const dataMeta = computed(() => {
    const localItemsCount = refBookingListTable.value
      ? refBookingListTable.value.localItems.length
      : 0;
    return {
      from: pageSize.value * (pageNumber.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (pageNumber.value - 1) + localItemsCount,
      of: totalBookings.value,
    };
  });

  watch(
    [
      pageNumber,
      pageSize,
      bookingTypeFilter,
      bookingStatusFilter,
      searchByDate,
    ],
    () => {
      refBookingListTable.value.refresh();
    }
  );

  const refreshTable = () => {
    refBookingListTable.value.refresh();
  };

  const clearSearchByDate = () => {
    searchByDate.value = '';
  };

  const fetchBookings = (ctx, callback) => {
    store
      .dispatch('booking/getBookings', {
        searchQuery: searchQuery.value,
        searchByDate: searchByDate.value === '' ? null : searchByDate.value,
        pageSize: pageSize.value,
        pageNumber: pageNumber.value,
        orderBy: orderBy.value,
        orderDesc: orderDesc.value,
        bookingStatusId: bookingStatusFilter.value,
        bookingTypeId: bookingTypeFilter.value,
      })
      .then((response) => {
        const { bookings, total } = response;
        callback(bookings);
        totalBookings.value = total;
      })
      .catch(() => {
        toast.error({
          component: ToastificationContent,
          props: {
            title: "Error fetching bookings' list",
            icon: 'AlertTriangleIcon',
          },
        });
      });
  };

  return {
    fetchBookings,
    tableColumns,
    pageSize,
    pageNumber,
    totalBookings,
    dataMeta,
    perPageOptions,
    searchQuery,
    orderBy,
    orderDesc,
    refBookingListTable,
    bookingStatusFilter,
    bookingTypeFilter,
    searchByDate,
    clearSearchByDate,
    refreshTable,
  };
}
