import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useBookingType = () => {
    const toast = useToast();

    const getBookingTypeList = async () => {
        try {
            return await store.dispatch('bookingType/getBookingTypes');
        } catch (error) {
            toast.error({
                component: ToastificationContent,
                props: {
                    title: 'Booking Types not found',
                    icon: 'XCircleIcon',
                },
            });
        }
    };

    return {
        getBookingTypeList,
    };
};

export default useBookingType;
