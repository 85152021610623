<template>
    <b-card no-body>
        <div class="m-2">
            <b-row>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-start mb-75"
                >
                    <label>Show</label>
                    <v-select
                        v-model="pageSize"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                    <label>entries</label>
                </b-col>

                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-start justify-content-sm-end"
                >

                    <b-form-group>
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="Press ENTER to search"
                            @keyup.enter="refBookingListTable.refresh()"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <div class="d-flex flex-md-row flex-column align-items-start justify-content-end w-100 px-1 px-md-0">
                    <div class="d-flex d-flex-row">
                        <flat-pickr
                            v-model="searchByDate"
                            class="form-control"
                            :config="{ dateFormat: 'd/m/Y', enableTime: false }"
                            readonly="false"
                            placeholder="Date"
                        />
                        <b-button
                            v-if="searchByDate !== ''"
                            variant="flat-secondary"
                            class="btn-icon ml-50"
                            @click="clearSearchByDate"
                        >
                            <feather-icon icon="XIcon" />
                        </b-button>
                    </div>

                    <v-select
                        v-model="bookingStatusFilter"
                        :reduce="(status) => status.id"
                        :options="bookingStatus"
                        class="invoice-filter-select my-50 my-md-0 mx-md-1"
                        placeholder="Select Status"
                        label="name"
                    >
                        <template #selected-option="{ name }">
                            <span class="text-truncate overflow-hidden">
                                {{ name }}
                            </span>
                        </template>

                        <template #option="{ name }">
                            <span class="text-truncate overflow-hidden">
                                {{ name }}
                            </span>
                        </template>
                    </v-select>

                    <v-select
                        v-model="bookingTypeFilter"
                        :reduce="(type) => type.id"
                        :options="bookingTypes"
                        class="invoice-filter-select"
                        placeholder="Select Types"
                        label="name"
                    >
                        <template #selected-option="{ name }">
                            <span class="text-truncate overflow-hidden">
                                {{ name }}
                            </span>
                        </template>

                        <template #option="{ name }">
                            <span class="text-truncate overflow-hidden">
                                {{ name }}
                            </span>
                        </template>
                    </v-select>
                </div>
            </b-row>
        </div>

        <b-table
            ref="refBookingListTable"
            :items="fetchBookings"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="orderDesc"
            class="position-relative"
        >
            <template #cell(voucherNum)="data">
                <b-link
                    :to="{
                        name: 'booking-detail',
                        params: { id: data.item.id },
                    }"
                    class="font-weight-bold"
                >
                    {{ data.value }}
                </b-link>
            </template>

            <template #cell(bookingType)="data">
                <b-badge
                    :variant="`light-${getBookingTypeVariantAndName(data.item.bookingType).variant}`"
                    class="text-capitalize"
                >
                    {{ getBookingTypeVariantAndName(data.item.bookingType).name }}
                </b-badge>
            </template>

            <template #cell(bookingStatusId)="data">
                <b-badge
                    :variant="`light-${getBookingStatusVariant(data.item.bookingStatusId)}`"
                    class="text-capitalize"
                >
                    {{ data.item.bookingStatus.name }}
                </b-badge>
            </template>

            <template #cell(company)="data">
                <span class="text-nowrap">
                    {{ data.item.company ? data.item.company.name : '' }}
                </span>
            </template>

            <template #cell(actions)="data">
                <b-dropdown
                    variant="link"
                    no-caret
                    right
                >
                    <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                    </template>
                    <b-dropdown-item
                        v-if="data.item.bookingStatusId !== BOOKING_STATUS_EDITED_ID && data.item.bookingStatusId !== BOOKING_STATUS_CANCELLED_ID"
                        @click="
                            $router.push({
                                name: 'booking-edit',
                                params: { id: data.item.id },
                            })"
                    >
                        <feather-icon
                            icon="EditIcon"
                            class="cursor-pointer mr-50"
                            size="16"
                        />
                        <span>Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="$router.push({
                        name: 'booking-detail',
                        params: { id: data.item.id },
                    })">
                        <feather-icon
                            icon="EyeIcon"
                            size="16"
                            class="mr-50"
                        />
                        <span>Preview</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                        v-if="data.item.bookingStatusId !== BOOKING_STATUS_EDITED_ID && data.item.bookingStatusId !== BOOKING_STATUS_CANCELLED_ID"
                        @click="changeStatus(data.item.id)"
                    >
                        <feather-icon
                            icon="DollarSignIcon"
                            size="16"
                            class="mr-50"
                        />

                        <span>Change Status</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </b-table>

        <div class="mx-2 mb-2">
            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-center justify-content-md-start"
                >
                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                        {{ dataMeta.of }} entries</span>
                </b-col>
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-center justify-content-md-end"
                >
                    <b-pagination
                        v-model="pageNumber"
                        :total-rows="totalBookings"
                        :per-page="pageSize"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-md-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>
import { avatarText } from '@core/utils/filter';
import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip, } from 'bootstrap-vue';
import { ref } from 'vue-demi';
import flatPickr from 'vue-flatpickr-component';
import Swal from 'sweetalert2';
import useBooking from '@/modules/booking/composables/useBooking';
import useBookingList from '@/modules/booking/composables/useBookingList';
import useBookingStatus from '@/modules/booking/composables/useBookingStatus';
import useBookingType from '@/modules/booking/composables/useBookingType';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import vSelect from 'vue-select';

export default {
    components: {
        BAvatar,
        BBadge,
        BButton,
        BCard,
        BCol,
        BDropdown,
        BDropdownItem,
        BFormGroup,
        BFormInput,
        BLink,
        BMedia,
        BPagination,
        BRow,
        BTable,
        BTooltip,
        vSelect,
        flatPickr,
    },
    setup() {
        const {
            fetchBookings,
            tableColumns,
            pageSize,
            pageNumber,
            totalBookings,
            dataMeta,
            perPageOptions,
            searchQuery,
            orderBy,
            orderDesc,
            refBookingListTable,
            bookingStatusFilter,
            bookingTypeFilter,
            searchByDate,
            clearSearchByDate,
            refreshTable,
        } = useBookingList();

        const { getBookingStatusList } = useBookingStatus();
        const { changeBookingStatus } = useBooking();
        const { getBookingTypeList } = useBookingType();
        const { BOOKING_STATUS_EDITED_ID, BOOKING_STATUS_CANCELLED_ID, getBookingStatusVariant, getBookingTypeVariantAndName } = useBookingUtils();

        const bookingTypes = ref([]);
        const bookingStatus = ref([]);

        const initData = async () => {
            const typeList = await getBookingTypeList();
            const statusList = await getBookingStatusList();
            bookingTypes.value = typeList;
            bookingStatus.value = statusList;
        }

        const changeStatus = async (bookingId) => {
            const { value } = await Swal.fire({
                title: 'Change Booking Status',
                text: 'This action will change the status of all linked transactions',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, continue!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            });

            if (!value) return;

            await changeBookingStatus(bookingId);
            refreshTable();
        }

        initData();

        return {
            // Const
            BOOKING_STATUS_EDITED_ID,
            BOOKING_STATUS_CANCELLED_ID,

            avatarText,
            bookingStatusFilter,
            bookingTypeFilter,
            bookingStatus,
            bookingTypes,
            dataMeta,
            fetchBookings,
            getBookingStatusVariant,
            getBookingTypeVariantAndName,
            orderBy,
            orderDesc,
            pageNumber,
            pageSize,
            perPageOptions,
            refBookingListTable,
            searchQuery,
            tableColumns,
            totalBookings,
            searchByDate,
            clearSearchByDate,
            changeStatus,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
