import useUtils from '@/composables/useUtils';
import store from '@/store';

const useBookingUtils = () => {
  const { formatDecimalNumber } = useUtils();

  const BOOKING_STATUS_PAID_ID = 1;
  const BOOKING_STATUS_PENDING_ID = 2;
  const BOOKING_STATUS_EDITED_ID = 3;
  const BOOKING_STATUS_CANCELLED_ID = 4;

  const getBookingRate = (booking) => {
    const { baggageNum, bookingType, enforcePaxRate, passengerNum } = booking;

    const {
      airportPickUpRate,
      airportPickUpRateExtraPassenger,
      pricePerPax,
      airportPickUpMaxPaxToApplyRateExtra,
    } = store.getters['setting/globalSettings'];

    let bookingRate = 0;

    if (bookingType.isAirportPickUp) {
      if (passengerNum !== 0) {
        let extraFee = 0;
        if (passengerNum > airportPickUpMaxPaxToApplyRateExtra) {
          extraFee =
            (passengerNum - airportPickUpMaxPaxToApplyRateExtra) *
            airportPickUpRateExtraPassenger;
        }
        bookingRate = airportPickUpRate + extraFee;
      }
    } else if (bookingType.isGibAir) {
      const { baggageFeePerBag, paxFee } =
        store.getters['gibAirRate/gibAirRate'];

      bookingRate = passengerNum * paxFee + baggageFeePerBag * baggageNum;
    } else if (bookingType.isPax) {
      if (enforcePaxRate) {
        bookingRate = pricePerPax * passengerNum;
      }
    } else if (bookingType.isCrew) {
      const crewRate = store.getters['crewRate/crewRateList'].find(
        ({ numOfCrew }) => numOfCrew === passengerNum
      );

      bookingRate = crewRate ? crewRate.rate : 0;
    }

    return formatDecimalNumber(bookingRate);
  };

  const getDriverRate = (booking) => {
    const {
      baggageNum,
      bookingType,
      passengerNum,
      bookingAdjustmentFee,
      bookingAdditionalFeesPerTaxi,
      bookingTaxis,
    } = booking;

    let taxiCommission = 0;

    if (bookingType.isGibAir) {
      const { baggageFeePerBagTaxi, paxFeeTaxi } =
        store.getters['gibAirRate/gibAirRate'];

      taxiCommission =
        passengerNum * paxFeeTaxi + baggageFeePerBagTaxi * baggageNum;
    }

    return formatDecimalNumber(
      bookingAdjustmentFee +
        taxiCommission +
        bookingAdditionalFeesPerTaxi * bookingTaxis.length
    );
  };

  const getOfficeRate = (booking) => {
    const {
      baggageNum,
      bookingAdditionalFeesTotalAmountToGta,
      bookingAdditionalFeesPerTaxi,
      bookingType,
      enforcePaxRate,
      heavyBaggageKgTotalFee,
      meterReaderTotalFee,
      passengerNum,
      waitingTimeTotalFee,
      wheelchairTotalFee,
      bookingTaxis,
    } = booking;

    const {
      airportPickUpRate,
      airportPickUpRateExtraPassenger,
      pricePerPax,
      baggagePerKiloRate,
      wheelchairRate,
      airportPickUpMaxPaxToApplyRateExtra,
      memberAirportPickUpGtaPercentageCommission,
      memberBaggageGtaPercentageCommission,
      memberMeterReaderGtaPercentageCommission,
      memberWaitTimeGtaPercentageCommission,
      memberWheelchairGtaPercentageCommission,
      nonMemberAirportPickUpGtaPercentageCommission,
      nonMemberBaggageGtaPercentageCommission,
      nonMemberMeterReaderGtaPercentageCommission,
      nonMemberWaitTimeGtaPercentageCommission,
      nonMemberWheelchairGtaPercentageCommission,
    } = store.getters['setting/globalSettings'];

    var heavyBaggageFeeTaxiMember =
      heavyBaggageKgTotalFee != 0
        ? bookingTaxis
            .filter((bt) => bt.taxi.isMember)
            .reduce(
              (total, taxi) => total + taxi.heavyBaggageKg * baggagePerKiloRate,
              0
            )
        : 0;

    var heavyBaggageFeeTaxiNonMember =
      heavyBaggageKgTotalFee != 0
        ? bookingTaxis
            .filter((bt) => !bt.taxi.isMember)
            .reduce(
              (total, taxi) => total + taxi.heavyBaggageKg * baggagePerKiloRate,
              0
            )
        : 0;

    const officeBaggageCommission = formatDecimalNumber(
      (memberBaggageGtaPercentageCommission / 100) * heavyBaggageFeeTaxiMember +
        (nonMemberBaggageGtaPercentageCommission / 100) *
          heavyBaggageFeeTaxiNonMember
    );

    var wheelchairFeeTaxiMember =
      wheelchairTotalFee != 0
        ? bookingTaxis
            .filter((bt) => bt.taxi.isMember)
            .reduce(
              (total, taxi) => total + taxi.wheelchairNum * wheelchairRate,
              0
            )
        : 0;

    var wheelchairFeeTaxiNonMember =
      wheelchairTotalFee != 0
        ? bookingTaxis
            .filter((bt) => !bt.taxi.isMember)
            .reduce(
              (total, taxi) => total + taxi.wheelchairNum * wheelchairRate,
              0
            )
        : 0;

    const officeWheelchairCommission = formatDecimalNumber(
      (memberWheelchairGtaPercentageCommission / 100) *
        wheelchairFeeTaxiMember +
        (nonMemberWheelchairGtaPercentageCommission / 100) *
          wheelchairFeeTaxiNonMember
    );

    var waitingTimeFeeTaxiMember =
      waitingTimeTotalFee != 0
        ? bookingTaxis
            .filter((bt) => bt.taxi.isMember)
            .reduce((total, taxi) => total + taxi.waitingTimeFee, 0)
        : 0;

    var waitingTimeFeeTaxiNonMember =
      waitingTimeTotalFee != 0
        ? bookingTaxis
            .filter((bt) => !bt.taxi.isMember)
            .reduce((total, taxi) => total + taxi.waitingTimeFee, 0)
        : 0;

    const officeWaitingTimeCommission = formatDecimalNumber(
      (memberWaitTimeGtaPercentageCommission / 100) * waitingTimeFeeTaxiMember +
        (nonMemberWaitTimeGtaPercentageCommission / 100) *
          waitingTimeFeeTaxiNonMember
    );

    var meterReaderFeeTaxiMember =
      meterReaderTotalFee != 0
        ? bookingTaxis
            .filter((bt) => bt.taxi.isMember)
            .reduce((total, taxi) => total + taxi.meterReaderFee, 0)
        : 0;

    var meterReaderFeeTaxiNonMember =
      meterReaderTotalFee != 0
        ? bookingTaxis
            .filter((bt) => !bt.taxi.isMember)
            .reduce((total, taxi) => total + taxi.meterReaderFee, 0)
        : 0;

    const officeMeterReaderCommission = formatDecimalNumber(
      (memberMeterReaderGtaPercentageCommission / 100) *
        meterReaderFeeTaxiMember +
        (nonMemberMeterReaderGtaPercentageCommission / 100) *
          meterReaderFeeTaxiNonMember
    );

    let officeCommission = 0;

    if (bookingType.isAirportPickUp) {
      if (passengerNum !== 0) {
        let extraFee = 0;
        if (passengerNum > airportPickUpMaxPaxToApplyRateExtra) {
          extraFee =
            (passengerNum - airportPickUpMaxPaxToApplyRateExtra) *
            airportPickUpRateExtraPassenger;
        }
        const feePerTaxi = (airportPickUpRate + extraFee) / bookingTaxis.length;

        var taxiMemberCommision =
          bookingTaxis.filter((bt) => bt.taxi !== null && bt.taxi.isMember)
            .length * memberAirportPickUpGtaPercentageCommission;
        var taxiNonMemberCommision =
          bookingTaxis.filter((bt) => bt.taxi !== null && !bt.taxi.isMember)
            .length * nonMemberAirportPickUpGtaPercentageCommission;

        officeCommission = formatDecimalNumber(
          (taxiMemberCommision / 100) * feePerTaxi +
            (taxiNonMemberCommision / 100) * feePerTaxi
        );
      }
    } else if (bookingType.isGibAir) {
      const { baggageFeePerBagGtaoffice, paxFeeGtaoffice } =
        store.getters['gibAirRate/gibAirRate'];
      officeCommission =
        paxFeeGtaoffice * passengerNum + baggageFeePerBagGtaoffice * baggageNum;
    } else if (bookingType.isPax) {
      if (enforcePaxRate) {
        officeCommission = passengerNum * pricePerPax - meterReaderTotalFee;
      }
    } else if (bookingType.isCrew) {
      const crewRate = store.getters['crewRate/crewRateList'].find(
        (rate) => rate.numOfCrew === passengerNum
      );
      if(crewRate){
        officeCommission = crewRate.officeFee
        const nonMembers = bookingTaxis.filter((bt) => !bt.taxi.isMember).length
        officeCommission += nonMembers * ((crewRate.driverRate / bookingTaxis.length) * 0.1)
      } else {
        officeCommission = 0;
      }
    }

    return formatDecimalNumber(
      bookingAdditionalFeesTotalAmountToGta +
        officeBaggageCommission +
        officeMeterReaderCommission +
        officeWaitingTimeCommission +
        officeWheelchairCommission +
        officeCommission -
        bookingAdditionalFeesPerTaxi * bookingTaxis.length
    );
  };

  const getTotalBookingFee = (booking, bookingRate) => {
    const meterReaderFee = booking.enforcePaxRate
      ? 0
      : booking.meterReaderTotalFee;

    const totalFees =
      (booking.bookingAdditionalFeesTotalAmountToGta * 100 +
        booking.heavyBaggageKgTotalFee * 100 +
        meterReaderFee * 100 +
        booking.wheelchairTotalFee * 100 +
        booking.waitingTimeTotalFee * 100 +
        bookingRate * 100) /
      100;

    const total =
      booking.bookingAdjustmentFee < 0
        ? parseFloat(totalFees) - booking.bookingAdjustmentFee * -1
        : parseFloat(totalFees) + booking.bookingAdjustmentFee;

    return total;
  };

  const storeBooking = (booking) => {
    store.commit('booking/storeBooking', {
      ...store.getters['booking/booking'],
      ...booking,
    });
  };

  const updateTaxiRate = (booking, rate) => {
    const { bookingType, passengerNum, bookingTaxis } = booking;
    const {
      memberWaitTimeGtaPercentageCommission,
      memberMeterReaderGtaPercentageCommission,
      memberBaggageGtaPercentageCommission,
      memberAirportPickUpGtaPercentageCommission,
      memberWheelchairGtaPercentageCommission,
      nonMemberWaitTimeGtaPercentageCommission,
      nonMemberMeterReaderGtaPercentageCommission,
      nonMemberBaggageGtaPercentageCommission,
      nonMemberAirportPickUpGtaPercentageCommission,
      nonMemberWheelchairGtaPercentageCommission,
      baggagePerKiloRate,
      airportPickUpRate,
      wheelchairRate,
      airportPickUpRateExtraPassenger,
      airportPickUpMaxPaxToApplyRateExtra,
    } = store.getters['setting/globalSettings'];
    const crewRate = store.getters['crewRate/crewRateList'].find(
      ({ numOfCrew }) => numOfCrew === passengerNum
    );

    const basicRate = rate / bookingTaxis.length;

    let taxiTotalAirporPickUpRate = 0;
    if (bookingType.isAirportPickUp) {
      if (passengerNum !== 0) {
        let extraFee = 0;
        if (passengerNum > airportPickUpMaxPaxToApplyRateExtra) {
          extraFee =
            (passengerNum - airportPickUpMaxPaxToApplyRateExtra) *
            airportPickUpRateExtraPassenger;
        }
        taxiTotalAirporPickUpRate =
          (airportPickUpRate + extraFee) / bookingTaxis.length;
      }
    }

    return bookingTaxis.map((bt) => {
      if (bt.taxi === null) return bt;

      let crewDriverRate = 0;
      if(bookingType.isCrew && crewRate) {
        const taxiRate = crewRate.driverRate / bookingTaxis.length;
        crewDriverRate = bt.taxi.isMember
        ? taxiRate
        : formatDecimalNumber(taxiRate - taxiRate * 0.1);
      }
        
      const waitingTimeTaxiRate = bt.taxi.isMember
        ? formatDecimalNumber(
            bt.waitingTimeFee -
              (memberWaitTimeGtaPercentageCommission / 100) * bt.waitingTimeFee
          )
        : formatDecimalNumber(
            bt.waitingTimeFee -
              (nonMemberWaitTimeGtaPercentageCommission / 100) *
                bt.waitingTimeFee
          );

      const meterReaderTaxiRate = bt.taxi.isMember
        ? formatDecimalNumber(
            bt.meterReaderFee -
              (memberMeterReaderGtaPercentageCommission / 100) *
                bt.meterReaderFee
          )
        : formatDecimalNumber(
            bt.meterReaderFee -
              (nonMemberMeterReaderGtaPercentageCommission / 100) *
                bt.meterReaderFee
          );

      const heavyBaggageTaxiRate = bt.taxi.isMember
        ? formatDecimalNumber(
            bt.heavyBaggageKg * baggagePerKiloRate -
              (memberBaggageGtaPercentageCommission / 100) *
                (bt.heavyBaggageKg * baggagePerKiloRate)
          )
        : formatDecimalNumber(
            bt.heavyBaggageKg * baggagePerKiloRate -
              (nonMemberBaggageGtaPercentageCommission / 100) *
                (bt.heavyBaggageKg * baggagePerKiloRate)
          );

      const wheelchairTaxiRate = bt.taxi.isMember
        ? formatDecimalNumber(
            bt.wheelchairNum * wheelchairRate -
              (memberWheelchairGtaPercentageCommission / 100) *
                (bt.wheelchairNum * wheelchairRate)
          )
        : formatDecimalNumber(
            bt.wheelchairNum * wheelchairRate -
              (nonMemberWheelchairGtaPercentageCommission / 100) *
                (bt.wheelchairNum * wheelchairRate)
          );

      const airportPickUpTaxiRate =
        taxiTotalAirporPickUpRate === 0
          ? 0
          : bt.taxi.isMember
          ? formatDecimalNumber(
              taxiTotalAirporPickUpRate -
                (memberAirportPickUpGtaPercentageCommission / 100) *
                  taxiTotalAirporPickUpRate
            )
          : formatDecimalNumber(
              taxiTotalAirporPickUpRate -
                (nonMemberAirportPickUpGtaPercentageCommission / 100) *
                  taxiTotalAirporPickUpRate
            );

      bt.taxiRate = formatDecimalNumber(
        basicRate +
          crewDriverRate +
          waitingTimeTaxiRate +
          meterReaderTaxiRate +
          heavyBaggageTaxiRate +
          airportPickUpTaxiRate +
          wheelchairTaxiRate
      );

      return bt;
    });
  };

  const getBookingStatusVariant = (id) => {
    if (id === BOOKING_STATUS_PAID_ID) return 'success';
    if (id === BOOKING_STATUS_PENDING_ID) return 'warning';
    if (id === BOOKING_STATUS_CANCELLED_ID) return 'danger';
    return 'secondary';
  };

  const getBookingTypeVariantAndName = (bookingType) => {
    if (bookingType.isCrew) return { variant: 'warning', name: 'CREW' };
    if (bookingType.isPax) return { variant: 'success', name: 'PAX' };
    if (bookingType.isGha) return { variant: 'info', name: 'GHA' };
    if (bookingType.isGibAir) return { variant: 'primary', name: 'GIB AIR' };
    if (bookingType.isAirportPickUp)
      return { variant: 'secondary', name: 'AIRPORT PICK UP' };
    if (bookingType.isCancelled)
      return { variant: 'secondary', name: 'CANCELLED' };
    return { variant: 'danger', name: 'TYPE NOT FOUD' };
  };

  const calculateTaxiNum = (passengers, settings) => {
    let taxiNum = 1;
    while (passengers > settings.maxCrewPassengersPerTaxi) {
      passengers = passengers - settings.maxCrewPassengersPerTaxi;
      taxiNum++;
    }
    return taxiNum;
  };

  const validateCrewBooking = (booking, settings) => {
    const taxiNumExpected = calculateTaxiNum(booking.passengerNum, settings);

    if (taxiNumExpected !== booking.bookingTaxis.length)
      return 'There are not enough taxi drivers';

    const validDropOff = store.getters['poi/poiList'].find(
      (poi) =>
        poi.name.toLowerCase() === booking.dropoffPoiName.toLowerCase() &&
        poi.isGibEntryOrExitPoint
    );
    const validPickUp = store.getters['poi/poiList'].find(
      (poi) =>
        poi.name.toLowerCase() === booking.pickupPoiName.toLowerCase() &&
        poi.isGibEntryOrExitPoint
    );

    if (!validDropOff && !validPickUp)
      return 'Pick up or Drop off has to be a Gib entry/exit';

    return null;
  };

  return {
    // Const
    BOOKING_STATUS_PAID_ID,
    BOOKING_STATUS_PENDING_ID,
    BOOKING_STATUS_EDITED_ID,
    BOOKING_STATUS_CANCELLED_ID,
    // Method
    getBookingStatusVariant,
    getBookingTypeVariantAndName,
    getBookingRate,
    getDriverRate,
    getTotalBookingFee,
    getOfficeRate,
    storeBooking,
    updateTaxiRate,
    validateCrewBooking,
  };
};

export default useBookingUtils;
